import React from 'react';
import './App.css';
import x3 from './assets/x3.jpg';
import p208 from './assets/p208.jpg'
import BackgroundSlider from 'react-background-slider'

function App() {
    return (
        <div className="App">
            <BackgroundSlider
                images={[x3, p208]}
                duration={7} transition={2}/>
            <div className={"infoBox"}>
                <h1>Tere tulemast Rent-Car.ee kodulehele!</h1>
                <h2>Meilt on võimalik rentida BMW X3 või Peugeot 208</h2>
                <p>Hinna pakkumise ja küsimuste korral võtke meiega ühendust!</p>
                <p>E-mail: info@rent-car.ee | Telefon: +372 506 2401</p>
            </div>
        </div>
    );
}

export default App;
